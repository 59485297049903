.selected-days {
  background-color: #3f51b5;
  color: white;
  text-transform: capitalize;
}

.capitalize span {
  text-transform: capitalize;
}

.unselected-days {
  background-color: white;
  border-color: #c4c4c4;
  color : black;
}