body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.helpSectionContainer {
  display: flex;
  justify-content: center;
}
 
.helpSectionContent{
  display: flex;
  width: 60%;
  flex-direction: column;
  justify-content: left;
}

/* Installation wizard CSS */
.sjs-user {
  line-height: 50px;
}

.sjs-install-ul {
  text-align: left;
  margin-top: 10px;
  list-style: circle;
}

.sjs-install-role {
  max-width: 110px;
  text-align: right;
  margin-top: 20px;
  margin-right: 20px;
}

.install-submit {
  margin-right: 5px;
}

.install-submit-btn {
  margin-top: 5px;
}