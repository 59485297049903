.container {
  margin-top: 15px;
}

.home-title {
  span {
    font-weight: 800;
  }
  border: 2px solid white;
  padding: 0px 5px;
  border-radius: 5px;
  cursor: pointer;
}

.home-bg-23395d {
  background-color: #23395d;
}

.home-list {
  background-color: #23395d;
  color: white;
}

.home-list-item-selected {
  color: #23395d;
  background-color: #3742a27d;

}

.home-icon-white {
  color: white;
}