.configuration {
  .MuiTableCell-head {
    font-weight: 600;
  }

  .width-50 {
    width: 50%;
  }

  @mixin change-margin-top-width($top: 30px, $width: 100%) {
    margin-top: $top;
    width: $width;
  }

  .mtw-30 {
    @include change-margin-top-width;
  }

  .mt-30 {
    margin-top: 30px;
  }

  .mtw-10 {
    @include change-margin-top-width($top: 10px);
  }

  .text {
    font-weight: 700;
    color: gray;
  }

  .text-500 {
    @extend .text;
    font-weight: 500;
  }

  .visually-hidden-input {
    clip: "rect(0 0 0 0)";
    clip-path: inset(50%);
    height: 1;
    overflow: "hidden";
    position: "absolute";
    bottom: 0;
    left: 0;
    white-space: "nowrap";
    width: 1;
  }
}
